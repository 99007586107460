/* Professional button styles */
.professional-button {
    background-color: #03385c; /* Professional blue color */
    color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .professional-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow on hover */
  }
  
  .professional-button:active {
    background-color: #004494; /* Even darker blue when pressed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow when pressed */
  }
  
  .professional-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(38, 143, 255, 0.5); /* Focus outline */
  }
  
  @media (max-width: 768px) {
    .professional-button {
      font-size: 14px; /* Adjust font size for smaller screens */
      padding: 8px 16px; /* Adjust padding for smaller screens */
    }
  }
  
  @media (max-width: 576px) {
    .professional-button {
      font-size: 12px; /* Further adjust font size for very small screens */
      padding: 6px 12px; /* Further adjust padding for very small screens */
    }
  }
  