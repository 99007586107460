/* Main container styling */
.container {
  max-width: 800px;
  margin: 0 auto;
}

/* Styling for the header */
.heading-color {
  color: #343a40; /* Dark heading color */
}

/* Styling for individual employee alerts */
.alert {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Flexbox layout to align image and text */
.alert.d-flex {
  align-items: flex-start;
  flex-wrap: wrap; /* Allows wrapping of content on smaller screens */
}

/* Styling for the employee image */
.alert img {
  border-radius: 75%;
  width: 150px; /* Adjusted width for better mobile scaling */
  height: 150px; /* Adjusted height */
  object-fit: cover; /* Ensures the image maintains aspect ratio */
  margin-right: 20px; /* Reduce margin for better fit on mobile */
}

/* Employee name styling */
.alert h5 {
  margin: 0 0 10px;
  font-size: 1.2em;
  color: #333;
}

/* Contact info section */
.contact-info {
  margin-top: 10px;
}

.contact-info p {
  margin: 3px 0;
}

.contact-info b {
  font-weight: bold;
  color: #007bff; /* Blue color for section heading */
}

.contact-info strong {
  font-weight: 600;
}

/* Role and description styling */
.alert p {
  margin: 0 0 10px;
}

/* Introductory text */
.intro-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 40px;
}

/* Individual card styling */
.about-card {
  background-color: #f0f8ff; /* Light background color */
  border: 1px solid #e0e0e0; /* Light border color */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Contact section adjustments for mobile */
@media (max-width: 768px) {
  .alert img {
    width: 100px; /* Smaller image size on mobile */
    height: 100px;
    margin-right: 0; /* Remove margin-right on mobile */
    margin-bottom: 15px; /* Add margin to create spacing between the image and text */
  }

  .alert.d-flex {
    flex-direction: column; /* Stack image and text vertically on small screens */
    align-items: center; /* Center-align both the image and text */
  }

  .alert div {
    text-align: center; /* Center text on mobile */
  }

  .contact-info {
    margin-top: 15px;
  }

  .contact-info p {
    word-break: break-word; /* Prevent long text from overflowing */
  }
}
