html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.heading-color {
  color: #03385c !important;
}
.text-color {
  color: #585858 !important;
}
.button-info {
  background-color: #03385c !important;
  color: white !important;
}
.hover-effect {
  transition: transform 0.2s;
}
.hover-effect:hover {
  transform: scale(1.05);
  z-index: 10;
}
.loading-animation {
  -webkit-animation: startAnimation 0.5s linear;
  animation: startAnimation 0.5s linear;
}
@keyframes startAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.logo {
  background-image: url("./assets/images/Dheemantha_logo.jpg");
  min-height: 100vh;
  background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: backgroundScroll 1s linear;
  animation: backgroundScroll 1s linear;
}

@keyframes backgroundScroll {
  0% {
    opacity: 0;
    background-position: bottom center;
  }
  100% {
    opacity: 1;
    background-position: top center;
  }
}

/* Adjustments for tablets and smaller devices */
@media (max-width: 768px) {
  .logo {
    display: none;
  }
}

.project-bg {
  background: url("./assets/images/projects_background.jpg") no-repeat;
}

@media screen and (max-width: 400px) {
  .nav-heading {
    font-size: 1rem !important;
  }
}
