.carousel-container {
    position: relative;
    overflow: hidden;
  }
  
  .carousel-image {
    object-fit: cover;
    height: 60vh; /* Adjust height as needed */
  }
  
  .carousel-caption {
    position: absolute;
    bottom: 20px; /* Adjust the distance from the bottom */
    left: 50% !important;
    transform: translateX(-50%);
    text-align: center;
    color: #fff; /* White text color for contrast */
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background */
    padding: 15px; /* Space around the text */
    border-radius: 8px; /* Rounded corners */
    max-width: 90%; /* Limit width to ensure it doesn't stretch too far */
    margin: 0 auto; /* Center horizontally */
  }
  
  .carousel-caption h3 {
    font-size: 2rem; /* Font size for heading */
    font-weight: bold; /* Bold text for emphasis */
    margin-bottom: 10px; /* Space below the heading */
  }
  
  .carousel-caption p {
    font-size: 1rem; /* Font size for paragraph */
    margin-bottom: 15px; /* Space below the paragraph */
  }
  
  .carousel-button {
    border: none;
    padding: 10px 20px; /* Adequate padding */
    font-size: 0.875rem; /* Font size for the button text */
    border-radius: 5px; /* Rounded corners */
    color: #fff; /* White text color */
    font-weight: bold; /* Bold text for emphasis */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
  }
  
  .carousel-button:hover { /* Darker shade for hover effect */
    color: #fff;
    text-decoration: none;
    transform: translateY(-2px); /* Slight lift effect on hover */
  }
  
  /* Media Queries for Responsiveness */
  
  @media (max-width: 768px) {
    .carousel-caption {
      bottom: 15px; /* Adjust for smaller screens */
      padding: 10px; /* Less padding for smaller screens */
      max-width: 95%; /* Ensure caption doesn't stretch too far */
    }
  
    .carousel-caption h3 {
      font-size: 1.5rem; /* Smaller font size for heading */
    }
  
    .carousel-caption p {
      font-size: 0.875rem; /* Smaller font size for paragraph */
    }
  
    .carousel-button {
      padding: 8px 16px; /* Adjust padding for smaller screens */
      font-size: 0.75rem; /* Smaller font size for the button text */
    }
  }
  
  @media (max-width: 576px) {
    .carousel-caption {
      bottom: 10px; /* Further adjust for extra-small screens */
      padding: 8px; /* Less padding for extra-small screens */
      max-width: 100%; /* Ensure full-width on very small screens */
    }
  
    .carousel-caption h3 {
      font-size: 1.25rem; /* Even smaller font size for heading */
    }
  
    .carousel-caption p {
      font-size: 0.75rem; /* Even smaller font size for paragraph */
    }
  
    .carousel-button {
      padding: 6px 12px; /* Further adjust padding for extra-small screens */
      font-size: 0.625rem; /* Smaller font size for button text */
    }
  }
  