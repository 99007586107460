.project-list-container {
    width: 100%;
    padding: 0 15px; /* Add horizontal padding for left and right */
  }
  
  .project-card {
    width: 100%;
    max-width: 300px; /* Set a fixed max-width for cards */
    margin: 0 15px; /* Add horizontal margin to space out cards */
    margin-bottom: 30px; /* Add vertical space between cards */
    border: 1px solid #ddd; /* Optional: add a border */
    border-radius: 8px; /* Optional: add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add shadow */
    transition: transform 0.3s ease; /* Optional: add a smooth hover effect */
  }
  
  .project-card:hover {
    transform: scale(1.02); /* Slightly scale up on hover */
  }
  
  .project-image {
    height: 200px; /* Fixed height for images */
    object-fit: cover; /* Ensure images cover the area without distortion */
  }
  
  .heading-color {
    color: #333; /* Adjust heading color */
  }
  
  .text-color {
    color: #666; /* Adjust text color */
  }
  