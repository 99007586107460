/* Header styling */
.navbar {
    padding: 0.5rem 1rem; /* Adjust padding for a more compact look */
  }
  
  .navbar-brand img {
    border-radius: 50%; /* Optional: for a rounded logo */
  }
  
  .navbar-brand b {
    font-size: 1.2rem; /* Slightly larger font size for better readability */
    font-weight: bold;
  }
  
  .navbar-toggler {
    border: none;
  }
  
  .navbar-collapse {
    text-align: center; /* Center-align text in collapsed view */
  }
  
  .nav-link {
    padding: 0.5rem 1rem; /* Adjust padding for better touch targets */
    font-size: 1rem; /* Consistent font size */
  }
  
  .navbar-nav .nav-link {
    color: #333; /* Dark text color */
    transition: color 0.3s; /* Smooth transition for hover effect */
  }
  
  .navbar-nav .nav-link:hover {
    color: #007bff; /* Highlight color on hover */
  }
  
  .navbar-nav .nav-link.active {
    font-weight: bold; /* Emphasize the active link */
  }
  
  .dropdown-menu {
    text-align: center; /* Center-align dropdown items */
  }
  
  .dropdown-item {
    padding: 0.75rem 1rem; /* Adjust padding for better touch targets */
  }
  
  @media (max-width: 767px) {
    .navbar-brand {
      text-align: center; /* Center-align brand text on mobile */
    }
    
    .navbar-brand img {
      margin-bottom: 0.5rem; /* Add spacing between logo and text on mobile */
    }
  
    .navbar-nav {
      margin-top: 1rem; /* Add margin at the top for spacing */
    }
  
    .nav-link {
      display: block; /* Stack links vertically on mobile */
      padding: 1rem 0; /* Add vertical padding for touch targets */
    }
  }
  